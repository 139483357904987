import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Card, notification, Modal, List, Checkbox, Pagination } from 'antd';
import { SendOutlined, PlusOutlined } from '@ant-design/icons';

const TGMessageSender = () => {
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [users, setUsers] = useState([]);  // 用户列表，包含ID和用户名
    const [selectedUserIds, setSelectedUserIds] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newUserId, setNewUserId] = useState('');
    const [newUserName, setNewUserName] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(10); // 每页显示的用户数量

    // 从localStorage加载缓存的用户列表和apiUrl
    useEffect(() => {
        const storedUsers = JSON.parse(localStorage.getItem('tgUsers')) || [];
        const storedUrl = localStorage.getItem('tgApiUrl');
        setUsers(storedUsers);
        if (storedUrl) {
            form.setFieldsValue({ apiUrl: storedUrl });
        }
    }, [form]);

    const handleAddUser = () => {
        if (newUserId && newUserName && !users.some(user => user.id === newUserId)) {
            const updatedUsers = [...users, { id: newUserId, name: newUserName }];
            setUsers(updatedUsers);
            localStorage.setItem('tgUsers', JSON.stringify(updatedUsers));
            setNewUserId('');
            setNewUserName('');
        }
    };

    const handleDeleteUser = (id) => {
        const updatedUsers = users.filter(user => user.id !== id);
        setUsers(updatedUsers);
        localStorage.setItem('tgUsers', JSON.stringify(updatedUsers));
    };

    const handleSelectUserIds = (checkedValues) => {
        setSelectedUserIds(checkedValues);
    };

    const onPageChange = (page) => {
        setCurrentPage(page);
    };

    const onFinish = async (values) => {
        const { apiUrl, message } = values;
        setLoading(true);

        // 缓存API URL
        localStorage.setItem('tgApiUrl', apiUrl);

        try {
            // 遍历选中的用户ID并发送消息
            for (const userId of selectedUserIds) {
                const response = await fetch('http://localhost:8000/api/v1/proxy/proxy/telegram', {
                    method: 'POST',
                    headers: {
                        "Content-Type": "application/json"
                    },
                    body: JSON.stringify({
                        apiUrl,
                        chatId: userId,
                        message
                    }),
                });

                if (!response.ok) {
                    throw new Error(`消息发送到 ${userId} 失败: ${response.statusText}`);
                }
            }

            notification.success({
                message: '消息发送成功',
                description: '你的消息已成功发送到所有选定的Telegram用户。',
            });
        } catch (error) {
            notification.error({
                message: '消息发送失败',
                description: `请求发送失败: ${error.message}`,
            });
        } finally {
            setLoading(false);
        }
    };

    const currentUsers = users.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    return (
        <Card
            title="发送Telegram消息"
            bordered={false}
            style={{ width: 400, margin: '0 auto', textAlign: 'center' }}
        >
            <Form
                form={form}
                name="tg_message_sender"
                layout="vertical"
                onFinish={onFinish}
                style={{ textAlign: 'left' }}
            >
                <Form.Item
                    name="apiUrl"
                    label="接口地址"
                    rules={[{ required: true, message: '请输入接口地址' }]}
                >
                    <Input placeholder="输入API接口地址" />
                </Form.Item>

                <Form.Item
                    label="选择用户"
                    rules={[{ required: true, message: '请选择至少一个用户' }]}
                >
                    <Button type="dashed" onClick={() => setIsModalVisible(true)}>
                        管理用户
                    </Button>
                    <Modal
                        title="管理用户"
                        visible={isModalVisible}
                        onCancel={() => setIsModalVisible(false)}
                        footer={null}
                    >
                        <Input
                            value={newUserId}
                            onChange={(e) => setNewUserId(e.target.value)}
                            placeholder="输入新的用户ID"
                        />
                        <Input
                            value={newUserName}
                            onChange={(e) => setNewUserName(e.target.value)}
                            placeholder="输入用户名"
                            style={{ marginTop: 8 }}
                            onPressEnter={handleAddUser}
                        />
                        <Button type="primary" onClick={handleAddUser} style={{ marginTop: 8 }}>
                            <PlusOutlined /> 添加用户
                        </Button>
                        <List
                            dataSource={currentUsers}
                            renderItem={(item) => (
                                <List.Item
                                    key={item.id}
                                    actions={[
                                        <Button
                                            key={`delete-${item.id}`}
                                            type="link"
                                            onClick={() => handleDeleteUser(item.id)}
                                        >
                                            删除
                                        </Button>
                                    ]}
                                >
                                    {item.name} ({item.id})
                                </List.Item>
                            )}
                            style={{ marginTop: 16 }}
                        />
                        <Pagination
                            current={currentPage}
                            pageSize={itemsPerPage}
                            total={users.length}
                            onChange={onPageChange}
                            style={{ marginTop: 16, textAlign: 'center' }}
                        />
                    </Modal>
                </Form.Item>

                <Form.Item
                    label="选择要发送消息的用户"
                    name="selectedUserIds"
                    rules={[{ required: true, message: '请选择至少一个用户' }]}
                >
                    <Checkbox.Group
                        options={users.map(user => ({ label: `${user.name} (${user.id})`, value: user.id }))}
                        value={selectedUserIds}
                        onChange={handleSelectUserIds}
                    />
                </Form.Item>

                <Form.Item
                    name="message"
                    label="消息内容"
                    rules={[{ required: true, message: '请输入消息内容' }]}
                >
                    <Input.TextArea placeholder="输入你想发送的消息" rows={4} />
                </Form.Item>

                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        icon={<SendOutlined />}
                        loading={loading}
                        block
                    >
                        分发消息
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};

export default TGMessageSender;
