import React, {useState, useEffect} from 'react';
import {Alert} from 'antd';

const AlertMessage = () => {
    const [ip, setIp] = useState('');

    useEffect(() => {
        fetch('https://httpbin.org/get')
            .then(response => response.json())
            .then(data => {
                const ipAddress = data.origin;
                setIp(ipAddress);
            })
            .catch(error => console.error('Error fetching IP:', error));
    }, []);

    return (<Alert
        description={<span>
                  当前公网IP：
                    <span style={{color: 'red'}}>{ip}</span>,<a href={'http://localhost:3000/config'}>配置管理</a>支持全局Socks5代理
                </span>}
        type="warning"
        style={{marginBottom: '10px'}}
    />);
};

export default AlertMessage;
