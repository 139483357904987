import React, { useState, useEffect } from 'react';
import { Table, Button, Row, Col, Typography, Input, Select } from 'antd';
import axios from 'axios';
import './WalletsTable.css';

const { Title } = Typography;
const { Option } = Select;

const WalletsTable = ({ fatherSelection }) => {
    const [wallets, setWallets] = useState([]);
    const [rightTableData, setRightTableData] = useState([]);
    const [pagination, setPagination] = useState({ current: 1, pageSize: 10, total: 0 });
    const [searchText, setSearchText] = useState('');
    const [searchCategory, setSearchCategory] = useState('address');

    useEffect(() => {
        fatherSelection.onRightTableDataChange(rightTableData);
    }, [rightTableData]);

    useEffect(() => {
        fetchWallets(pagination.current, pagination.pageSize, searchText, searchCategory);
    }, [pagination.current, pagination.pageSize, searchText, searchCategory]);

    const fetchWallets = async (page, pageSize, search = '', category = 'address') => {
        const response = await axios.get(`http://127.0.0.1:8000/api/v1/wallets`, {
            params: {
                skip: (page - 1) * pageSize,
                limit: pageSize,
                [category]: search
            }
        });
        const data = response.data;
        const filteredWallets = data.wallets.filter(wallet => !rightTableData.some(rightWallet => rightWallet.id === wallet.id));
        setWallets(filteredWallets);
        setPagination({
            current: page,
            pageSize: pageSize,
            total: data.total_count
        });
    };

    const handleTableChange = (pagination) => {
        setPagination(pagination);
    };

    const moveSelectedToRight = () => {
        const selectedWallets = wallets.filter(wallet => wallet.selected);
        setRightTableData([...rightTableData, ...selectedWallets]);
        setWallets(wallets.filter(wallet => !wallet.selected));
    };

    const removeFromRight = (id) => {
        const walletToRemove = rightTableData.find(wallet => wallet.id === id);
        if (walletToRemove) {
            setWallets([...wallets, walletToRemove]);
            setRightTableData(rightTableData.filter(wallet => wallet.id !== id));
        }
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const handleCategoryChange = (value) => {
        setSearchCategory(value);
    };

    const clearSearch = () => {
        setSearchText('');
        fetchWallets(pagination.current, pagination.pageSize, '', searchCategory);
    };

    const columns = [
        {
            title: '环境',
            dataIndex: 'ads_environment',
            key: 'ads_environment',
            align: 'center',
            width: 40
        },
        {
            title: '组',
            dataIndex: 'group',
            key: 'group',
            align: 'center',
            width: 40
        },
        {
            title: '钱包类型',
            dataIndex: 'wallet_type',
            key: 'wallet_type',
            align: 'center',
            width: 40
        },
        {
            title: '地址',
            dataIndex: 'address',
            key: 'address',
            align: 'center',
            width: 200
        }
    ];

    const rightColumns = [
        ...columns,
        {
            title: '操作',
            key: 'action',
            align: 'center',
            width: 80,
            render: (text, record) => (
                <Button type="link" onClick={() => removeFromRight(record.id)}>删除</Button>
            ),
        }
    ];

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const newWallets = wallets.map(wallet => ({
                ...wallet,
                selected: selectedRowKeys.includes(wallet.id)
            }));
            setWallets(newWallets);
        }
    };

    return (
        <div style={{ padding: '16px' }}>
            <Row gutter={16}>
                <Col span={11}>
                    <Title level={4}>钱包列表</Title>
                    <Input.Group compact style={{ marginBottom: 16 }}>
                        <Select defaultValue="address" onChange={handleCategoryChange} style={{ width: '30%' }}>
                            <Option value="address">地址</Option>
                            <Option value="group">组</Option>
                            <Option value="exchange_address">交易所地址</Option>
                            <Option value="ads_environment">ADS环境</Option>
                        </Select>
                        <Input.Search
                            placeholder="搜索钱包"
                            onSearch={handleSearch}
                            value={searchText}
                            onChange={(e) => setSearchText(e.target.value)}
                            style={{ width: '55%' }}
                        />
                    </Input.Group>
                    <Table
                        rowKey='id'
                        dataSource={wallets}
                        rowSelection={rowSelection}
                        columns={columns}
                        size="small"
                        pagination={{
                            ...pagination,
                            showSizeChanger: true,
                            pageSizeOptions: ['10', '20', '50', '100', '200', '400', '800'],
                        }}
                        onChange={handleTableChange}
                        scroll={{ y: 400 }}
                        style={{ height: 400 }}
                    />
                </Col>
                <Col span={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <Button type="primary" onClick={moveSelectedToRight} style={{ backgroundColor: '#1890ff', color: 'white' }}>确认选择</Button>
                </Col>
                <Col span={11}>
                    <Title level={4}>选中提币地址 ({rightTableData.length})</Title>
                    <Table
                        rowKey='id'
                        dataSource={rightTableData}
                        columns={rightColumns}
                        size="small"
                        pagination={false}
                        scroll={{ y: 400 }}
                        style={{ height: 400 }}
                    />
                </Col>
            </Row>
        </div>
    );
};

export default WalletsTable;
