import React, {useState, useEffect} from 'react';
import {Table, Tabs, Button, Modal, Input, Row, Col, notification} from 'antd';
import axios from 'axios';
import {saveAs} from 'file-saver';
import {
    SyncOutlined, CheckCircleFilled, CloseCircleFilled, MinusCircleFilled, QuestionCircleOutlined
} from '@ant-design/icons';

const {TabPane} = Tabs;

const OperationsTable = ({keyProp}) => {
    const [operations, setOperations] = useState([]);
    const [pagination, setPagination] = useState({current: 1, pageSize: 10, total: 0});
    const [statusFilter, setStatusFilter] = useState('all');
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [taskId, setTaskId] = useState('');
    const [batchId, setBatchId] = useState('');
    const [walletAddress, setWalletAddress] = useState('');
    const [autoRefresh, setAutoRefresh] = useState(false);

    useEffect(() => {
        fetchOperations();
        let interval;
        if (autoRefresh) {
            interval = setInterval(fetchOperations, 3000);
        }
        return () => {
            if (interval) {
                clearInterval(interval);
            }
        };
    }, [pagination.current, pagination.pageSize, statusFilter, taskId, batchId, walletAddress, autoRefresh, keyProp]);

    const fetchOperations = async () => {
        try {
            const response = await axios.get(`http://127.0.0.1:8000/api/v1/tasks`, {
                params: {
                    skip: (pagination.current - 1) * pagination.pageSize,
                    limit: pagination.pageSize,
                    status: statusFilter
                }
            });
            const data = response.data;
            setOperations(data.tasks);
            setPagination({
                current: pagination.current, pageSize: pagination.pageSize, total: data.total_count
            });
        } catch (error) {
            console.error('Error fetching operations:', error);
        }
    };

    const fetchOperationsQuery = async () => {
        try {
            const response = await axios.get(`http://127.0.0.1:8000/api/v1/tasks`, {
                params: {
                    skip: (pagination.current - 1) * pagination.pageSize,
                    limit: pagination.pageSize,
                    status: statusFilter,
                    task_id: taskId,
                    batch_id: batchId,
                    wallet_address: walletAddress
                }
            });
            const data = response.data;
            setOperations(data.tasks);
            setPagination({
                current: pagination.current, pageSize: pagination.pageSize, total: data.total_count
            });
        } catch (error) {
            console.error('Error fetching operations:', error);
        }
    };

    const handleSearchChange = (setter) => (e) => {
        setter(e.target.value);
    };

    const handleTabChange = (key) => {
        setStatusFilter(key);
        setPagination({current: 1, pageSize: pagination.pageSize, total: 0});
    };

    const onSelectChange = (selectedRowKeys) => {
        setSelectedRowKeys(selectedRowKeys);
    };

    const toggleAutoRefresh = () => {
        setAutoRefresh(!autoRefresh);
    };

    const handleCancel = async () => {
        Modal.confirm({
            title: '您确定要取消所选的提币任务吗？',
            content: '此操作无法撤销。',
            okText: '是的，取消它们',
            okType: 'danger',
            cancelText: '保留任务',
            cancelButtonProps: {
                type: 'primary'
            },
            onOk: async () => {
                let successCount = 0;
                let failureCount = 0;

                for (const taskId of selectedRowKeys) {
                    try {
                        await axios.put(`http://127.0.0.1:8000/api/v1/tasks/${taskId}/cancel`);
                        successCount++;
                    } catch (error) {
                        failureCount++;
                    }
                }

                if (successCount > 0) {
                    notification.success({
                        message: '任务取消成功', description: `成功取消了${successCount}个任务。`
                    });
                }
                if (failureCount > 0) {
                    notification.error({
                        message: '任务取消失败', description: `有${failureCount}个任务取消失败。`
                    });
                }

                setSelectedRowKeys([]);
                fetchOperations();
            }
        });
    };

    const rowSelection = {
        selectedRowKeys, onChange: onSelectChange,
    };

    const handleExport = async () => {
        try {
            const response = await axios.get(`http://127.0.0.1:8000/api/v1/tasks`, {
                params: {
                    skip: 0, limit: 99999, status: statusFilter
                }
            });
            const {data} = response;
            exportToCsv(data.tasks);
        } catch (error) {
            console.error('Error fetching data for export:', error);
        }
    };

    const handleClear = () => {
        setTaskId('');
        setBatchId('');
        setWalletAddress('');
        console.log("任务ID、批次ID和钱包地址已清空");
    };

    const exportToCsv = (data) => {
        if (!data.length) {
            console.error('No data to export');
            return;
        }
        const BOM = '\uFEFF';
        const csvHeader = Object.keys(data[0]).join(',') + '\n';
        const csvRows = data.map(row => {
            return Object.keys(data[0]).map(fieldName => {
                let field = row[fieldName] === null ? '' : row[fieldName].toString();
                field = field.replace(/"/g, '""');
                if (field.includes(',') || field.includes('\n') || field.includes('"')) {
                    field = `"${field}"`;
                }
                return field;
            }).join(',');
        });
        const csvString = BOM + [csvHeader, ...csvRows].join('\n');
        const blob = new Blob([csvString], {type: 'text/csv;charset=utf-8;'});
        saveAs(blob, 'operations.csv');
    };

const operationColumns = [
    {
        title: '任务ID',
        dataIndex: 'id',
        key: 'id',
        align: 'center',
        width: 40
    },
    {
        title: '钱包ID',
        dataIndex: 'group',
        key: 'group',
        align: 'center',
        width: 40
    },
    {
        title: 'ADS环境',
        dataIndex: 'ads_environment',
        key: 'ads_environment',
        align: 'center',
        width: 40
    },
    {
        title: '批次ID',
        dataIndex: 'batch_id',
        key: 'batch_id',
        align: 'center',
        width: 200
    },
    {
        title: '任务类型',
        dataIndex: 'task_type',
        key: 'task_type',
        align: 'center',
        width: 40
    },
    {
        title: '交易所',
        dataIndex: 'exchange_type',
        key: 'exchange_type',
        align: 'center',
        width: 40
    },
    {
        title: '钱包类型',
        dataIndex: 'wallet_type',
        key: 'wallet_type',
        align: 'center',
        width: 40
    },
    {
        title: '钱包地址',
        dataIndex: 'wallet_address',
        key: 'wallet_address',
        align: 'center',
        width: 120
    },
    {
        title: '状态',
        dataIndex: 'status',
        key: 'status',
        align: 'center',
        width: 40,
        render: (status) => renderStatusLogo(status)
    },
    {
        title: '提币金额',
        dataIndex: 'withdrawal_amount',
        key: 'withdrawal_amount',
        align: 'center',
        width: 60
    },
    {
        title: '预计完成时间',
        dataIndex: 'estimated_execution_time',
        key: 'estimated_execution_time',
        align: 'center',
        width: 180
    },
    {
        title: '实际完成时间',
        dataIndex: 'actual_execution_time',
        key: 'actual_execution_time',
        align: 'center',
        width: 180
    },
    {
        title: '结果',
        dataIndex: 'result',
        key: 'result',
        align: 'center',
        width: 120
    }
];


    function renderStatusLogo(status) {
        switch (status) {
            case "0":
                return <SyncOutlined spin style={{fontSize: 24, color: '#1890ff'}} title="等待"/>;
            case "1":
                return <SyncOutlined spin style={{fontSize: 24, color: '#faad14'}} title="进行中"/>;
            case "2":
                return <CheckCircleFilled style={{fontSize: 24, color: '#52c41a'}} title="完成"/>;
            case "3":
                return <CloseCircleFilled style={{fontSize: 24, color: '#f5222d'}} title="失败"/>;
            case "4":
                return <MinusCircleFilled style={{fontSize: 24, color: '#d9d9d9'}} title="取消"/>;
            default:
                return <QuestionCircleOutlined style={{fontSize: 24, color: '#595959'}} title="未知状态"/>;
        }
    }

    return (<div>
            <Tabs defaultActiveKey="all" onChange={handleTabChange}>
                <TabPane tab="所有任务" key="all"/>
                <TabPane tab="等待中" key="0"/>
                <TabPane tab="进行中" key="1"/>
                <TabPane tab="已完成" key="2"/>
                <TabPane tab="失败" key="3"/>
                <TabPane tab="已取消" key="4"/>
            </Tabs>

            <Row gutter={16} style={{marginBottom: 16}}>
                <Col>
                    <Input placeholder="任务ID" value={taskId} onChange={handleSearchChange(setTaskId)}/>
                </Col>
                <Col>
                    <Input placeholder="批次ID" value={batchId} onChange={handleSearchChange(setBatchId)}
                           style={{width: '100%'}}/>
                </Col>
                <Col>
                    <Input placeholder="钱包地址" value={walletAddress} onChange={handleSearchChange(setWalletAddress)}
                           style={{width: '100%'}}/>
                </Col>
                <Col>
                    <Button type="primary" onClick={fetchOperationsQuery}>搜索</Button>
                </Col>
                <Col>
                    <Button type="default" onClick={handleClear}>清空</Button>
                </Col>
            </Row>

            <div style={{marginBottom: 16, display: 'flex', justifyContent: 'flex-end'}}>
                <Button type="primary" onClick={toggleAutoRefresh} danger={autoRefresh}>
                    {autoRefresh ? '关闭刷新' : '启动自动刷新'}
                </Button>

                <span style={{margin: '0 8px'}}></span>
                <Button type="primary" danger onClick={handleCancel} disabled={selectedRowKeys.length === 0}>
                    取消选中提币任务
                </Button>
                <span style={{margin: '0 8px'}}></span>
                <Button type="primary" onClick={handleExport}>导出数据</Button>
            </div>
            <Table
                rowKey='id'
                dataSource={operations}
                pagination={pagination}
                columns={operationColumns}
                onChange={(pagination) => setPagination(pagination)}
                size="small"
                rowSelection={rowSelection}
                scroll={{y: 400}}
                style={{height: 400}}
            />
        </div>);
};

export default OperationsTable;
