import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Table, Pagination, Input, Button, message, Modal, Form, Checkbox, Dropdown, Menu, Tooltip} from 'antd';
import * as XLSX from 'xlsx';
import {CheckOutlined, CloseOutlined} from '@ant-design/icons';

const Wallets = () => {
    const [wallets, setWallets] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1, pageSize: 10, total: 0
    });
    const [searchParams, setSearchParams] = useState({
        address: '', group: '', exchange_address: '', ads_environment: ''
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [currentWallet, setCurrentWallet] = useState(null);
    const [form] = Form.useForm();
    const [confirmEditModalVisible, setConfirmEditModalVisible] = useState(false);
    const [confirmDeleteModalVisible, setConfirmDeleteModalVisible] = useState(false);
    const [bulkDeleteModalVisible, setBulkDeleteModalVisible] = useState(false);
    const defaultVisibleColumns = ['id', 'ads_environment', 'group', 'wallet_id', 'wallet_type', 'address', 'exchange_address', 'proxy', 'note', 'action'];

    const [visibleColumns, setVisibleColumns] = useState(() => {
        const savedColumns = localStorage.getItem('visibleColumns');
        return savedColumns ? JSON.parse(savedColumns) : [...defaultVisibleColumns];
    });

    useEffect(() => {
        fetchWallets(pagination.current, pagination.pageSize);
    }, []);

    useEffect(() => {
        localStorage.setItem('visibleColumns', JSON.stringify(visibleColumns));
    }, [visibleColumns]);

    const fetchWallets = async (page, pageSize, filters = {}) => {
        try {
            const response = await axios.get(`http://127.0.0.1:8000/api/v1/wallets`, {
                params: {
                    skip: (page - 1) * pageSize, limit: pageSize, ...filters
                }
            });
            const data = response.data;
            setWallets(data.wallets);
            setPagination({
                current: page, pageSize: pageSize, total: data.total_count
            });
        } catch (error) {
            message.error('获取钱包列表失败');
        }
    };

    const handlePageChange = (page, pageSize) => {
        fetchWallets(page, pageSize, searchParams);
    };

    const handleSearch = () => {
        fetchWallets(1, pagination.pageSize, searchParams);
    };

    const handleReset = () => {
        setSearchParams({
            address: '', group: '', exchange_address: '', ads_environment: ''
        });
        fetchWallets(1, pagination.pageSize, {
            address: '', group: '', exchange_address: '', ads_environment: ''
        });
    };

    const handleInputChange = (e) => {
        setSearchParams({...searchParams, [e.target.name]: e.target.value});
    };

    const handleFileChange = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const formData = new FormData();
            formData.append('file', file);

            try {
                await axios.post('http://127.0.0.1:8000/api/v1/wallets/import', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                fetchWallets(pagination.current, pagination.pageSize, searchParams);
                message.success('导入成功');
            } catch (error) {
                console.error('导入钱包时出错:', error);
                if (error.response && error.response.data && error.response.data.message) {
                    message.error(`导入失败: ${error.response.data.message}`);
                } else {
                    message.error('导入失败，出现意外错误');
                }
            }
        }
    };

    const handleDownloadTemplate = () => {
        const templateData = [['钱包类型', '地址', '私钥', '备注', '分组', '钱包ID', '交易所地址', 'ads环境', '代理IP']];
        const templateSheet = XLSX.utils.aoa_to_sheet(templateData);
        const templateWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(templateWorkbook, templateSheet, 'Wallets Template');
        XLSX.writeFile(templateWorkbook, 'wallets_template.xlsx');
    };

    const handleEdit = (wallet) => {
        setCurrentWallet(wallet);
        form.setFieldsValue(wallet);
        setEditModalVisible(true);
    };

    const handleDelete = (walletId) => {
        setCurrentWallet({id: walletId});
        setDeleteModalVisible(true);
    };

    const handleCustomDelete = (walletId) => {
        // Placeholder for custom delete logic
        console.log(`Custom delete action for wallet with ID ${walletId}`);
    };

    const confirmDelete = async () => {
        try {
            await axios.delete(`http://127.0.0.1:8000/api/v1/wallets/${currentWallet.id}`);
            fetchWallets(pagination.current, pagination.pageSize, searchParams);
            message.success('删除成功');
            setDeleteModalVisible(false);
            setConfirmDeleteModalVisible(false);
            setCurrentWallet(null);
        } catch (error) {
            message.error('删除失败');
        }
    };

    const handleBulkDelete = () => {
        setBulkDeleteModalVisible(true);
    };

    const confirmBulkDelete = async () => {
        let successfulDeletes = 0;
        let failedDeletes = 0;

        for (const id of selectedRowKeys) {
            try {
                await axios.delete(`http://127.0.0.1:8000/api/v1/wallets/${id}`);
                successfulDeletes++;
            } catch (error) {
                failedDeletes++;
            }
        }

        fetchWallets(pagination.current, pagination.pageSize, searchParams);

        if (successfulDeletes > 0) {
            message.success(`批量删除成功: ${successfulDeletes} 个钱包`);
        }
        if (failedDeletes > 0) {
            message.error(`批量删除失败: ${failedDeletes} 个`);
        }

        setBulkDeleteModalVisible(false);
        setSelectedRowKeys([]);
    };

    const handleModalOk = () => {
        setConfirmEditModalVisible(true);
    };

    const confirmEdit = async () => {
        try {
            const values = await form.validateFields();
            if (currentWallet) {
                await axios.put(`http://127.0.0.1:8000/api/v1/wallets/${currentWallet.id}`, values);
                message.success('更新成功');
            } else {
                await axios.post('http://127.0.0.1:8000/api/v1/wallets', values);
                message.success('创建成功');
            }
            setEditModalVisible(false);
            setConfirmEditModalVisible(false);
            setModalVisible(false);
            fetchWallets(pagination.current, pagination.pageSize, searchParams);
            form.resetFields();
        } catch (error) {
            message.error('操作失败');
        }
    };

    const handleModalCancel = () => {
        setEditModalVisible(false);
        setConfirmEditModalVisible(false);
        setModalVisible(false);
        setCurrentWallet(null);
        form.resetFields();
    };

    const handleColumnChange = (checkedValues) => {
        setVisibleColumns(checkedValues);
    };

    const columns = [{
        title: 'ADS环境', dataIndex: 'ads_environment', key: 'ads_environment', className: 'no-wrap'
    }, {title: '组', dataIndex: 'group', key: 'group', className: 'no-wrap'}, {
        title: '钱包ID', dataIndex: 'wallet_id', key: 'wallet_id', className: 'no-wrap'
    }, {
        title: '钱包类型', dataIndex: 'wallet_type', key: 'wallet_type', className: 'no-wrap'
    }, {title: '地址', dataIndex: 'address', key: 'address', className: 'no-wrap'}, {
        title: '交易所地址', dataIndex: 'exchange_address', key: 'exchange_address', className: 'no-wrap'
    }, {
        title: '代理IP',
        dataIndex: 'proxy',
        key: 'proxy',
        className: 'no-wrap',
        render: (text) => (<Tooltip title={text}>
            {text ? <CheckOutlined style={{color: 'green'}}/> : <CloseOutlined style={{color: 'red'}}/>}
        </Tooltip>)
    }, {
        title: '备注', dataIndex: 'note', key: 'note', className: 'no-wrap'
    }, {
        title: '操作', key: 'action', className: 'no-wrap', render: (_, record) => (<span>
                <Button type="link" onClick={() => handleEdit(record)}>编辑</Button>
                <Button type="link" onClick={() => handleDelete(record.id)} danger>删除</Button>
            </span>)
    }].filter(column => visibleColumns.includes(column.key));

    const rowSelection = {
        selectedRowKeys, onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    const columnOptions = [{label: 'ADS环境', value: 'ads_environment'}, {
        label: '组', value: 'group'
    }, {
        label: '钱包ID', value: 'wallet_id'
    }, {label: '钱包类型', value: 'wallet_type'}, {label: '地址', value: 'address'}, {
        label: '交易所地址', value: 'exchange_address'
    }, {label: '代理IP', value: 'proxy'}, {label: '备注', value: 'note'}, {label: '操作', value: 'action'},];

    const menu = (<Menu>
        <Menu.Item>
            <Checkbox.Group options={columnOptions} value={visibleColumns} onChange={handleColumnChange}/>
        </Menu.Item>
    </Menu>);

    return (<div style={{width: '100%', margin: '0 auto'}}>
        <div style={{marginBottom: 20, display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
            <div>
                <Input placeholder="地址" name="address" value={searchParams.address} onChange={handleInputChange}
                       style={{width: 200, marginRight: 8}}/>
                <Input placeholder="分组" name="group" value={searchParams.group} onChange={handleInputChange}
                       style={{width: 200, marginRight: 8}}/>
                <Input placeholder="交易所地址" name="exchange_address" value={searchParams.exchange_address}
                       onChange={handleInputChange} style={{width: 200, marginRight: 8}}/>
                <Input placeholder="ADS环境" name="ads_environment" value={searchParams.ads_environment}
                       onChange={handleInputChange} style={{width: 200, marginRight: 8}}/>
                <Button type="primary" onClick={handleSearch} style={{marginRight: 8}}>搜索</Button>
                <Button onClick={handleReset}>重置</Button>
            </div>
            <div>
                <Dropdown overlay={menu} placement="bottomLeft">
                    <Button type="default" style={{marginLeft: 8}}>选择列</Button>
                </Dropdown>
                <Button type="default" style={{marginLeft: 8}} onClick={handleDownloadTemplate}>下载模板</Button>
                <input type="file" onChange={handleFileChange} style={{display: 'none'}}/>
                <Button type="primary" style={{marginLeft: 8}}
                        onClick={() => document.querySelector('input[type="file"]').click()}>导入</Button>
                <Button type="primary" style={{marginLeft: 8}} onClick={() => {
                    setModalVisible(true);
                    setCurrentWallet(null);
                    form.resetFields();
                }}>新增</Button>
                <Button type="primary" style={{marginLeft: 8}} onClick={handleBulkDelete}
                        disabled={!selectedRowKeys.length} danger>删除</Button>
            </div>
        </div>
        <Table
            columns={columns}
            dataSource={wallets}
            rowKey="id"
            rowSelection={rowSelection}
            pagination={false}
            style={{marginBottom: 20}}
            scroll={{y: 580, x: 'max-content'}} // 固定高度并增加纵向和横向滚动
        />
        <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
        />
        <Modal
            title={currentWallet ? '编辑钱包' : '新增钱包'}
            visible={modalVisible || editModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
        >
            <Form form={form} layout="vertical">
                <Form.Item name="address" label="地址" rules={[{required: true, message: '请输入地址'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="private_key" label="私钥" rules={[{required: true, message: '请输入私钥'}]}>
                    <Input.Password/>
                </Form.Item>
                <Form.Item name="wallet_type" label="钱包类型"
                           rules={[{required: true, message: '请输入钱包类型'}]}>
                    <Input/>
                </Form.Item>
                <Form.Item name="proxy" label="代理IP">
                    <Input/>
                </Form.Item>
                <Form.Item name="group" label="组">
                    <Input/>
                </Form.Item>
                <Form.Item name="exchange_address" label="交易所地址">
                    <Input/>
                </Form.Item>
                <Form.Item name="ads_environment" label="ADS环境">
                    <Input/>
                </Form.Item>
                <Form.Item name="note" label="备注">
                    <Input/>
                </Form.Item>
            </Form>
        </Modal>
        <Modal
            title="再次确认编辑"
            visible={confirmEditModalVisible}
            onOk={confirmEdit}
            onCancel={() => setConfirmEditModalVisible(false)}
        >
            <p>你确定要提交这些更改吗？此操作不可撤销。</p>
        </Modal>
        <Modal
            title="确认删除"
            visible={deleteModalVisible}
            onOk={() => setConfirmDeleteModalVisible(true)}
            onCancel={() => setDeleteModalVisible(false)}
        >
            <p>确定要删除这个钱包吗？</p>
        </Modal>
        <Modal
            title="再次确认删除"
            visible={confirmDeleteModalVisible}
            onOk={confirmDelete}
            onCancel={() => setConfirmDeleteModalVisible(false)}
        >
            <p>删除操作不可撤销，确认删除吗？</p>
        </Modal>
        <Modal
            title="确认批量删除"
            visible={bulkDeleteModalVisible}
            onOk={confirmBulkDelete}
            onCancel={() => setBulkDeleteModalVisible(false)}
        >
            <p>删除操作不可撤销，确认删除选中的钱包吗？</p>
        </Modal>
    </div>);
};

export default Wallets;


