import React, {useState, useEffect} from 'react';
import {Button, Form, message, Upload, Table, Row, Col, Tag, Input} from 'antd';
import {UploadOutlined} from '@ant-design/icons';
import * as XLSX from 'xlsx';
import 'antd/dist/reset.css';

const {Item} = Form;

const MyForm = () => {
    const [form] = Form.useForm();
    const [tableData, setTableData] = useState([]);
    const [uploadVisible, setUploadVisible] = useState(true);
    const [loadingWalletId, setLoadingWalletId] = useState(null);
    const [loadingImport, setLoadingImport] = useState(false); // 导入加载状态
    const [ipAddress, setIpAddress] = useState('');
    const [selectedWallets, setSelectedWallets] = useState([]);
    const [walletPassword, setWalletPassword] = useState(''); // 钱包密码
    const [key, setKey] = useState(''); // 密钥

    useEffect(() => {
        const cachedData = localStorage.getItem('MoveUseWallet');
        if (cachedData) {
            const parsedData = JSON.parse(cachedData);
            setTableData(parsedData);
            setUploadVisible(false);
        }

        const savedIpAddress = localStorage.getItem('MoveSocks5');
        if (savedIpAddress) {
            setIpAddress(savedIpAddress);
        }
    }, []);

    const onFinish = () => {
        message.success('表单提交成功！');
        console.log('提交的数据:', tableData);
    };

    const handleFileUpload = (file) => {
        const reader = new FileReader();
        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, {type: 'array'});
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(sheet);

            const filteredData = json.map((row) => ({
                ads: row.ADS,
                walletId: row.walletID,
                mnemonic: row.mnemonic,
                evmAddress: row['EVM-address'],
                suiAddress: row['SUI-Address'],
                aptosAddress: row['APTOS-Adress'],
                evmResult: null,
                suiResult: null,
                aptosResult: null,
                importStatus: null, // 新增字段，用于标识导入状态
            }));

            localStorage.setItem('MoveUseWallet', JSON.stringify(filteredData));
            setTableData(filteredData);
            setUploadVisible(false);
            message.success('Excel 数据已成功导入并保存到 localStorage 中！');
        };
        reader.readAsArrayBuffer(file);
        return false;
    };

    const renderEllipsis = (text) => {
        const maxLength = 10;
        if (text && text.length > maxLength) {
            return `${text.slice(0, 6)}...${text.slice(-4)}`;
        }
        return text;
    };

    const handleGetWater = async (record) => {
        const {evmAddress, suiAddress, aptosAddress, walletId, ads} = record;

        if (!ipAddress) {
            message.error('Socks5 IP不能为空');
            return;
        }

        setLoadingWalletId(walletId);

        try {
            const response = await fetch('http://127.0.0.1:8000/api/v1/move/get_allToken', {
                method: 'POST', headers: {
                    'Content-Type': 'application/json', 'Accept': 'application/json',
                }, body: JSON.stringify({
                    evm_address: evmAddress,
                    sui_address: suiAddress,
                    aptos_address: aptosAddress,
                    move_socks5: ipAddress,
                    ads: ads
                }),
            });

            const result = await response.json();

            if (response.ok) {
                const {evm_result, sui_result, aptos_result} = result.data;
                message.success(`领取成功！\nEVM: ${evm_result}\nSUI: ${sui_result}\nAPTOS: ${aptos_result}`);

                const updatedData = tableData.map(item => item.walletId === walletId ? {
                    ...item, evmResult: evm_result, suiResult: sui_result, aptosResult: aptos_result
                } : item);
                setTableData(updatedData);
                localStorage.setItem('MoveUseWallet', JSON.stringify(updatedData)); // 确保这里使用的是 updatedData
            } else {
                message.error(`领取失败: ${result.message}`);
                const updatedData = tableData.map(item => item.walletId === walletId ? {
                    ...item, evmResult: '领取失败', suiResult: '领取失败', aptosResult: '领取失败'
                } : item);
                setTableData(updatedData);
                localStorage.setItem('MoveUseWallet', JSON.stringify(updatedData)); // 确保这里使用的是 updatedData
            }
        } catch (error) {
            message.error('请求失败，请稍后重试');
            const updatedData = tableData.map(item => item.walletId === walletId ? {
                ...item, evmResult: '领取失败', suiResult: '领取失败', aptosResult: '领取失败'
            } : item);
            setTableData(updatedData);
            localStorage.setItem('MoveUseWallet', JSON.stringify(updatedData)); // 确保这里使用的是 updatedData
        } finally {
            setLoadingWalletId(null);
        }
    };

    const handleBatchGetWater = async () => {
        if (!ipAddress) {
            message.error('Socks5 IP不能为空');
            return;
        }

        for (const wallet of selectedWallets) {
            const record = tableData.find(item => item.walletId === wallet);
            await handleGetWater(record);
        }
    };

    const handleImportWallet = async () => {
        if (key === '' || walletPassword === '') {
            message.error('秘钥或钱包密码不能为空');
            return;
        }
        if (selectedWallets.length === 0) {
            message.error('请先选择要导入的钱包');
            return;
        }

        setLoadingImport(true); // 开始加载
        for (const walletId of selectedWallets) {
            const record = tableData.find(item => item.walletId === walletId);

            if (!record) {
                message.error(`未找到钱包记录: ${walletId}`);
                continue; // 如果没有找到记录，跳过这个循环
            }

            const {mnemonic, ads} = record; // 提取必要字段
            const payload = {
                mnemonic: mnemonic, wallet_password: walletPassword, // 从输入框中获取钱包密码
                key: key, // 从输入框中获取密钥
                ads_id: ads, // 假设 ads 是您想要发送的 ID
                walletId: walletId,
            };
            try {
                const response = await fetch('http://127.0.0.1:8000/api/v1/auto/import_wallet/', {
                    method: 'POST', headers: {
                        'Content-Type': 'application/json', 'Accept': 'application/json',
                    }, body: JSON.stringify(payload),
                });

                const result = await response.json();

                if (response.ok) {
                    message.success(`钱包导入成功: ${result.message}`);
                    // 只更新当前导入的项
                    setTableData(prevData => {
                        const updatedData = prevData.map(item => item.walletId === walletId ? {
                            ...item,
                            importStatus: '导入成功'
                        } : item);
                        localStorage.setItem('MoveUseWallet', JSON.stringify(updatedData)); // 确保这里使用的是 updatedData
                        return updatedData;
                    });
                } else {
                    message.error(`钱包导入失败: ${result.message}`);
                }
            } catch (error) {
                message.error('请求失败，请稍后重试');
            }
        }
        setLoadingImport(false); // 结束加载
    };


    const handleIpChange = (e) => {
        const value = e.target.value;
        setIpAddress(value);
        localStorage.setItem('MoveSocks5', value);
    };

    const handleSelectChange = (selectedRowKeys) => {
        setSelectedWallets(selectedRowKeys);
    };

    const columns = [{title: 'ADS', dataIndex: 'ads', key: 'ads', width: 80}, {
        title: '钱包ID', dataIndex: 'walletId', key: 'walletId', width: 120
    }, {
        title: '钱包助记词', dataIndex: 'mnemonic', key: 'mnemonic', render: renderEllipsis, width: 130
    }, {title: 'EVM地址', dataIndex: 'evmAddress', key: 'evmAddress', width: 300}, {
        title: 'SUI地址', dataIndex: 'suiAddress', key: 'suiAddress'
    }, {title: 'APTOS地址', dataIndex: 'aptosAddress', key: 'aptosAddress'}, {
        title: '导入状态',
        key: 'importStatus',
        dataIndex: 'importStatus',
        width: 100,
        render: (text) => (<Tag color={text === '导入成功' ? 'green' : 'red'}>
            {text || '未导入'}
        </Tag>),
    }, {
        title: '领水结果', key: 'waterClaimResult', render: (text, record) => (<div>
            {record.evmResult && (<Tag color={record.evmResult === '领取成功' ? 'green' : 'red'}>
                EVM: {record.evmResult}
            </Tag>)}
            {record.suiResult && (<Tag color={record.suiResult === '领取成功' ? 'green' : 'red'}>
                SUI: {record.suiResult}
            </Tag>)}
            {record.aptosResult && (<Tag color={record.aptosResult === '领取成功' ? 'green' : 'red'}>
                APT: {record.aptosResult}
            </Tag>)}
        </div>),
    }, {
        title: '操作', key: 'action', render: (text, record) => (<span>
                    <Button
                        type="link"
                        onClick={() => handleGetWater(record)}
                        loading={loadingWalletId === record.walletId}
                    >
                        领水
                    </Button>
                </span>),
    },];

    return (<div style={{padding: 20}}>
        <Form
            form={form}
            name="my_form"
            onFinish={onFinish}
            layout="vertical"
        >
            <Row>
                {uploadVisible && (<Col>
                    <Item>
                        <Upload
                            beforeUpload={handleFileUpload}
                            showUploadList={false}
                        >
                            <Button icon={<UploadOutlined/>}>点击上传</Button>
                        </Upload>
                    </Item>
                </Col>)}
                <Col>
                    <Item label="钱包密码">
                        <Input
                            value={walletPassword}
                            onChange={(e) => setWalletPassword(e.target.value)}
                            placeholder="请输入钱包密码"
                        />
                    </Item>
                </Col>
                <Col>
                    <Item label="密钥">
                        <Input
                            value={key}
                            onChange={(e) => setKey(e.target.value)}
                            placeholder="请输入密钥"
                        />
                    </Item>
                </Col>
                <Col>
                    <Item>
                        <Button type="primary" onClick={handleBatchGetWater}>
                            批量领水
                        </Button>
                    </Item>
                </Col>
                <Col>
                    <Item>
                        <Button type="primary" onClick={handleImportWallet} loading={loadingImport}>
                            批量导入钱包
                        </Button>
                    </Item>
                </Col>
                <Col>
                    <Item>
                        <Button type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Item>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Item label="Socks5 IP 地址">
                        <Input
                            value={ipAddress}
                            onChange={handleIpChange}
                            placeholder="请输入 IP 地址"
                        />
                    </Item>
                </Col>
            </Row>
        </Form>

        <Table
            dataSource={tableData}
            columns={columns}
            rowKey="walletId"
            pagination={false}
            scroll={{y: 800, x: '100%'}}
            rowSelection={{
                selectedRowKeys: selectedWallets, onChange: handleSelectChange,
            }}
        />
    </div>);
};

export default MyForm;
