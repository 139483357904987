import React, {useState} from 'react';
import {BrowserRouter as Router, Routes, Route, NavLink} from 'react-router-dom';
import {Layout, Menu} from 'antd';
import {
    WalletOutlined, DollarOutlined, ToolOutlined, SettingOutlined, ThunderboltOutlined, MessageOutlined, CloudUploadOutlined, SyncOutlined, BarChartOutlined
} from '@ant-design/icons';
import './App.css';

// 引入页面组件
import Wallet from '../src/components/Wallet';
import ExchangeWithdrawal from './components/exchange/ExchangeWithdrawal';
import ConfigPage from "../src/components/config/config";
import Linea from '../src/components/Linea';
import TextFormatter from '../src/components/TextFormatter';
import EncryptionComponent from '../src/components/EncryptionComponent';
import AdsPower from '../src/components/adspower';
import BearToken from '../src/components/bearToken';
import TGMessageSender from '../src/components/TGMessageSender';
import MoveMent from '../src/components/MoveMent';

const {Header, Content} = Layout;

const navItems = [
    {label: '钱包概览', key: 'home', icon: <WalletOutlined/>, path: "/", component: Wallet},
    {label: 'Ads', key: 'ads', icon: <ThunderboltOutlined/>, path: "/ads", component: AdsPower},
    {label: '交易所提币', key: 'ExchangeWithdrawal', icon: <DollarOutlined/>, path: "/exchange", component: ExchangeWithdrawal},
    {label: 'Linea分数LXP-L', key: 'linea', icon: <BarChartOutlined/>, path: "/linea", component: Linea},
    {label: '配置管理', key: 'config', icon: <SettingOutlined/>, path: "/config", component: ConfigPage},
    {label: '钱包格式转换', key: 'txtmatter', icon: <SyncOutlined/>, path: "/wallettxt", component: TextFormatter},
    {label: '加密解密', key: 'cryption', icon: <ToolOutlined/>, path: "/cryption", component: EncryptionComponent},
    {label: 'Bera领水', key: 'bera', icon: <CloudUploadOutlined/>, path: "/bera", component: BearToken},
    {label: 'TG消息', key: 'tgmsg', icon: <MessageOutlined/>, path: "/tgmsg", component: TGMessageSender},
    {label: 'MOVEMENT', key: 'move', icon: <SyncOutlined/>, path: "/move", component: MoveMent}
];

const App = () => {
    const [current, setCurrent] = useState('home');

    const handleClick = e => setCurrent(e.key);

    return (
        <Router>
            <Layout style={{height: '100vh'}}>
                <Header style={{width: '100%'}}>
                    <Menu onClick={handleClick} selectedKeys={[current]} mode="horizontal" style={{width: '100%'}}>
                        {navItems.map(({key, icon, label, path}) => (
                            <Menu.Item key={key} icon={icon}>
                                <NavLink to={path}>
                                    {label}
                                </NavLink>
                            </Menu.Item>
                        ))}
                    </Menu>
                </Header>
                <Content className="content">
                    <Routes>
                        {navItems.map(({path, component: Component}) => (
                            <Route key={path} path={path} element={<div className="table-container"><Component/></div>} />
                        ))}
                    </Routes>
                </Content>
            </Layout>
        </Router>
    );
};

export default App;
