import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Input, Spin, message, Button, Tooltip, Row, Col, Progress } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';

const BearToken = () => {
    // State declarations
    const [wallets, setWallets] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1, pageSize: 999999, total: 0,
    });
    const [searchParams, setSearchParams] = useState({
        address: '', group: '', exchange_address: '', ads_environment: '',
    });
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [walletData, setWalletData] = useState(() => {
        const saved = localStorage.getItem('bearTokens');
        return saved ? JSON.parse(saved) : {};
    });
    const [buttonLoading, setButtonLoading] = useState({});
    const [ip, setIp] = useState('');
    const [startInterval, setStartInterval] = useState('');
    const [endInterval, setEndInterval] = useState('');
    const [progress, setProgress] = useState(0);
    const [remainingTasks, setRemainingTasks] = useState(0);

    // Fetch wallets on component mount
    useEffect(() => {
        fetchWallets(pagination.current, pagination.pageSize);
    }, []);

    // Update localStorage whenever walletData changes
    useEffect(() => {
        localStorage.setItem('bearTokens', JSON.stringify(walletData));
    }, [walletData]);

    // Function to fetch wallets
    const fetchWallets = async () => {
        setLoading(true);
        try {
            const response = await axios.get('http://127.0.0.1:8000/api/v1/wallets?group=老钱包', {
                params: {
                    skip: 1, limit: 999999,
                },
            });
            const data = response.data;
            setWallets(data.wallets);
            // Initialize wallet data for new wallets
            const initialWalletData = {};
            data.wallets.forEach((wallet) => {
                initialWalletData[wallet.id] = walletData[wallet.id] || {
                    proxy: '', lastWateringTime: '从未领水', status: '', detail: ''
                };
            });
            setWalletData(initialWalletData);
        } catch (error) {
            console.log(error)
            message.error('获取钱包列表失败');
        } finally {
            setLoading(false);
        }
    };

    // Handle page change
    const handlePageChange = (page, pageSize) => {
        fetchWallets(page, pageSize);
    };

    // Handle proxy change
    const handleProxyChange = (id, value) => {
        setWalletData((prevState) => ({
            ...prevState, [id]: {
                ...prevState[id], proxy: value,
            },
        }));
    };

    // Handle IP change
    const handleIpChange = (e) => {
        setIp(e.target.value);
    };

    // Handle start interval change
    const handleStartIntervalChange = (e) => {
        setStartInterval(e.target.value);
    };

    // Handle end interval change
    const handleEndIntervalChange = (e) => {
        setEndInterval(e.target.value);
    };

    // Handle watering action
    const handleWatering = async (record, customIp = '') => {
        setButtonLoading((prevState) => ({ ...prevState, [record.id]: true }));
        try {
            const response = await axios.post('http://127.0.0.1:8000/api/v1/bear/bearToken', {
                wallet_address: record.address,
                proxyUrl: customIp || walletData[record.id]?.proxy
            });

            const { detail, status } = response.data;
            const currentTime = new Date().toLocaleString();
            if (detail.status === 1) {
                setWalletData((prevState) => ({
                    ...prevState, [record.id]: {
                        ...prevState[record.id], lastWateringTime: currentTime, status: 'success', detail: detail.msg,
                    },
                }));
                message.success('领水成功');
            } else {
                setWalletData((prevState) => ({
                    ...prevState, [record.id]: {
                        ...prevState[record.id], lastWateringTime: currentTime, status: 'error', detail: detail.msg,
                    },
                }));
                message.error('领水失败，请稍后再试');
            }
        } catch (error) {
            const currentTime = new Date().toLocaleString();
            console.error('Error during watering:', error);
            setWalletData((prevState) => ({
                ...prevState, [record.id]: {
                    ...prevState[record.id], lastWateringTime: currentTime, status: 'error', detail: error.message,
                },
            }));
            message.error('领水失败，请稍后再试');
        } finally {
            setButtonLoading((prevState) => ({ ...prevState, [record.id]: false }));
        }
    };

    // Handle bulk watering action
    const handleBulkWatering = async () => {
        let tasks = selectedRowKeys.length;
        setRemainingTasks(tasks);
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const key = selectedRowKeys[i];
            const record = wallets.find(wallet => wallet.id === key);
            if (record) {
                const lastWateringTime = new Date(walletData[record.id]?.lastWateringTime);
                const now = new Date();
                const hoursSinceLastWatering = (now - lastWateringTime) / 1000 / 60 / 60;

                if (isNaN(hoursSinceLastWatering) || hoursSinceLastWatering > 8) {
                    await handleWatering(record, ip);
                    const interval = Math.floor(Math.random() * (endInterval - startInterval + 1)) + parseInt(startInterval, 10);
                    await new Promise(resolve => setTimeout(resolve, interval * 1000));
                }
            }
            // Update progress
            tasks--;
            setRemainingTasks(tasks);
            setProgress(Math.round(((i + 1) / selectedRowKeys.length) * 100));
        }
    };

    // Table columns
    const columns = [{ title: 'ID', dataIndex: 'id', key: 'id', className: 'no-wrap' }, {
        title: '组', dataIndex: 'group', key: 'group', className: 'no-wrap'
    }, { title: '钱包ID', dataIndex: 'wallet_id', key: 'wallet_id', className: 'no-wrap' }, {
        title: '钱包类型', dataIndex: 'wallet_type', key: 'wallet_type', className: 'no-wrap'
    }, { title: '地址', dataIndex: 'address', key: 'address', className: 'no-wrap' }, {
        title: '代理IP', dataIndex: 'proxy', key: 'proxy', className: 'no-wrap', render: (text, record) => (<Input
            value={walletData[record.id]?.proxy || ''}
            onChange={(e) => handleProxyChange(record.id, e.target.value)}
            style={{ width: '100%' }}
        />),
    }, {
        title: '上次领水时间', dataIndex: 'lastWateringTime', key: 'lastWateringTime', sorter: (a, b) => {
            const aTime = new Date(walletData[a.id]?.lastWateringTime || '1970-01-01').getTime();
            const bTime = new Date(walletData[b.id]?.lastWateringTime || '1970-01-01').getTime();
            return aTime - bTime;
        }, render: (text, record) => walletData[record.id]?.lastWateringTime || '从未领水',
    }, {
        title: '状态', key: 'status', render: (text, record) => {
            const status = walletData[record.id]?.status;
            const detail = walletData[record.id]?.detail || '';
            if (status === 'success') {
                return <Tooltip title={detail}><CheckCircleOutlined style={{ color: 'green' }} /></Tooltip>;
            } else if (status === 'error') {
                return <Tooltip title={detail}><CloseCircleOutlined style={{ color: 'red' }} /></Tooltip>;
            } else {
                return null;
            }
        },
    }, {
        title: '操作',
        key: 'action',
        render: (text, record) => (
            <Button type="primary" size={'small'} loading={buttonLoading[record.id]} onClick={() => handleWatering(record, ip)}>
                领水
            </Button>),
    },];

    // Row selection configuration
    const rowSelection = {
        selectedRowKeys, onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        },
    };

    return (
        <div style={{ width: '100%', margin: '0 auto' }}>
            <Spin spinning={loading}>
                <Row gutter={16} style={{ marginBottom: 20 }}>
                    <Col span={6}>
                        <Input
                            placeholder="全局代理IP"
                            value={ip}
                            onChange={handleIpChange}
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            placeholder="开始间隔时间 (秒)"
                            value={startInterval}
                            onChange={handleStartIntervalChange}
                        />
                    </Col>
                    <Col span={6}>
                        <Input
                            placeholder="结束间隔时间 (秒)"
                            value={endInterval}
                            onChange={handleEndIntervalChange}
                        />
                    </Col>
                    <Col span={6}>
                        <Button type="primary" size={'small'} onClick={handleBulkWatering}>
                            批量领水
                        </Button>
                    </Col>
                </Row>
                <Progress percent={progress} status="active" />
                <div style={{ marginBottom: 20 }}>
                    当前剩余任务: {remainingTasks}
                </div>
                <Table
                    columns={columns}
                    dataSource={wallets}
                    rowKey="id"
                    rowSelection={rowSelection}
                    pagination={false}
                    style={{ marginBottom: 20 }}
                    scroll={{ y: 580, x: 'max-content' }}
                    onChange={(pagination, filters, sorter) => handlePageChange(pagination.current, pagination.pageSize)}
                />
            </Spin>
        </div>
    );
};

export default BearToken;
