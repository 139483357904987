import React, {useState} from 'react';
import {Form, Button, Divider, message, Modal} from 'antd';
import axios from 'axios';
import './ExchangeWithdrawal.css';
import ExchangeSelection from './ExchangeSelection';
import ExchangeFormFields from './ExchangeFormFields';
import WalletsTable from './WalletsTable';
import OperationsTable from './OperationsTable';
import AlertMessage from './AlertMessage';

const ExchangeWithdrawal = () => {
    const [formData, setFormData] = useState({
        exchange: 'okx',
        ApiKey: '',
        SecretKey: '',
        Password: '',
        tokenName: '',
        network: '',
        startTime: '',
        endTime: '',
        startAmount: '',
        endAmount: '',
        decimals: '',
    });

    const [reloadTable, setReloadTable] = useState(false); // 声明并初始化 'reloadTable' 变量
    const [rightTableData, setRightTableData] = useState([]);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setFormData({...formData, [name]: value});
    };

    const handleRightTableDataChange = (newRightTableData) => {
        setRightTableData(newRightTableData);
    };

    const handleSubmit = async () => {
        const selectedWalletIDs = rightTableData.map(wallet => wallet.id); // 使用 rightTableData 中的 id 作为钱包ID
        console.log(JSON.stringify(selectedWalletIDs))
        if (!selectedWalletIDs.length) {
            message.error('没有选择钱包地址！');
            return;
        }

        // Prepare the data to be submitted
        const requestData = {
            ...formData, walletid: selectedWalletIDs.map(id => ({id})) // 将选中的钱包ID构建成对象数组
        };

        // Check for any empty required fields
        const requiredFields = ['exchange', 'ApiKey', 'SecretKey', 'tokenName', 'network', 'startTime', 'endTime', 'startAmount', 'endAmount', 'decimals'];
        const missingFields = requiredFields.filter(field => !requestData[field]);

        if (missingFields.length) {
            message.error(`${missingFields.join(', ')} 字段不能为空。`);
            return; // Stop the function if there are any missing required fields
        }

        // 显示确认模态框
        Modal.confirm({
            title: '您确定要提交这些任务吗？',
            content: '提交后将开始处理提币任务。',
            okText: '提交',
            okType: 'primary',
            cancelText: '取消',
            onOk: async () => {
                try {
                    const response = await axios.post('http://127.0.0.1:8000/api/v1/tasks/batch', requestData);
                    if (response.status === 200) { // 200 而不是 201
                        const {task_count, msg} = response.data;
                        message.success(`任务提交成功！创建了 ${task_count} 个任务。消息: ${msg}`);
                    } else {
                        const errorMsg = response.data && response.data.error ? response.data.error : '未知错误：任务提交失败，请稍后重试！';
                        message.error(errorMsg);
                    }
                } catch (error) {
                    message.error('任务提交失败，请稍后重试！');
                }
            }
        });
    };


    return (<div style={{padding: '20px'}}>
        <AlertMessage/>
        <ExchangeSelection exchange={formData.exchange}
                           setExchange={(value) => setFormData({...formData, exchange: value})}/>
        <Form layout="vertical">
            <Button type="primary" onClick={handleSubmit} style={{marginBottom: '20px'}}>提交任务</Button>
            <ExchangeFormFields exchange={formData.exchange}
                                includePassword={formData.exchange === 'okx' || formData.exchange === 'bitget'}
                                formData={formData} onInputChange={handleInputChange}/>
        </Form>
        <WalletsTable fatherSelection={{onRightTableDataChange: handleRightTableDataChange}}/>
        <Divider>运行状态</Divider>
        <OperationsTable key={reloadTable ? 'reload' : 'no-reload'}/> {/* 使用 key 属性控制重新加载表格 */}
    </div>);
};

export default ExchangeWithdrawal;
