import React, {useState, useEffect, useCallback, useMemo} from 'react';
import {Table, Input, Button, message, Pagination} from 'antd';
import axios from 'axios';

const AdsPower = () => {
    const [wallets, setWallets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [buttonStatusMap, setButtonStatusMap] = useState({});
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1, pageSize: 10, total: 0
    });

    useEffect(() => {
        const storedWallets = JSON.parse(localStorage.getItem('wallets')) || [];
        fetchWallets(pagination.current, pagination.pageSize, {}, storedWallets);
    }, [pagination.current, pagination.pageSize]);

    const fetchWallets = async (page, pageSize, filters = {}, storedWallets = []) => {
        setLoading(true);
        try {
            const response = await axios.get(`http://127.0.0.1:8000/api/v1/wallets`, {
                params: {
                    skip: (page - 1) * pageSize, limit: pageSize, ...filters
                }
            });
            const data = response.data;
            const fetchedWallets = data.wallets.map(wallet => {
                const storedWallet = storedWallets.find(stored => stored.id === wallet.id || stored.ads_environment === wallet.ads_environment);
                return {
                    ...wallet, operationInput: storedWallet ? storedWallet.operationInput : ''
                };
            });
            setWallets(fetchedWallets);
            setPagination(prev => ({
                ...prev, current: page, pageSize: pageSize, total: data.total_count
            }));
        } catch (error) {
            message.error('获取钱包列表失败');
        } finally {
            setLoading(false);
        }
    };

    const updateLocalStorage = (walletId, adsEnvironment, operationInput) => {
        let storedWallets = JSON.parse(localStorage.getItem('wallets')) || [];
        const existingWallet = storedWallets.find(wallet => wallet.id === walletId || wallet.ads_environment === adsEnvironment);

        if (existingWallet) {
            storedWallets = storedWallets.map(wallet => wallet.id === walletId || wallet.ads_environment === adsEnvironment ? {
                id: walletId, ads_environment: adsEnvironment, operationInput
            } : wallet);
        } else {
            storedWallets.push({id: walletId, ads_environment: adsEnvironment, operationInput});
        }

        localStorage.setItem('wallets', JSON.stringify(storedWallets));
    };

    const handleInputChange = useCallback((e, record) => {
        const {value} = e.target;
        setWallets(prevWallets => prevWallets.map(wallet => wallet.id === record.id ? {
            ...wallet, operationInput: value
        } : wallet));
        updateLocalStorage(record.id, record.ads_environment, value);
    }, []);

    const updateButtonStatus = useCallback((id, loading, type = '') => {
        setButtonStatusMap(prevStatusMap => ({
            ...prevStatusMap, [id]: {loading, type}
        }));
    }, []);

    const handleStart = useCallback(async (record) => {
        try {
            updateButtonStatus(record.id, true);
            const userInfoResponse = await axios.get(`http://127.0.0.1:8000/api/v1/proxy/api/v1/user/list?serial_number=${record.ads_environment}`);
            await new Promise(resolve => setTimeout(resolve, 1000)); // 每秒一次查询限制
            const userInfo = userInfoResponse.data.data.list[0];
            if (!userInfo) {
                message.error('未找到相关用户信息');
                updateButtonStatus(record.id, false, 'error');
                return;
            }

            const browserStartResponse = await axios.get('http://127.0.0.1:8000/api/v1/proxy/api/v1/browser/start', {
                params: {
                    serial_number: record.ads_environment, user_id: userInfo.user_id, open_tabs: 1
                }
            });
            if (browserStartResponse.data.code === 0) {
                const {selenium, puppeteer, webdriver} = browserStartResponse.data.data.ws;
                message.success(`成功启动浏览器，Selenium接口：${selenium}，Puppeteer接口：${puppeteer}，Webdriver路径：${webdriver}`);
                updateButtonStatus(record.id, false, 'success');

                setButtonStatusMap(prevStatusMap => ({
                    ...prevStatusMap, [record.id]: {loading: false, type: 'running'}
                }));
            } else {
                message.error(`启动浏览器失败: ${browserStartResponse.data.msg}`);
                updateButtonStatus(record.id, false, 'error');
            }
        } catch (error) {
            console.error('Failed to start browser:', error);
            message.error('启动浏览器请求失败');
            updateButtonStatus(record.id, false, 'error');
        }
    }, [updateButtonStatus]);

    const handleModify = useCallback(async (record) => {
        try {
            updateButtonStatus(record.id, true);
            const userInfoResponse = await axios.get(`http://127.0.0.1:8000/api/v1/proxy/api/v1/user/list?serial_number=${record.ads_environment}`);
            await new Promise(resolve => setTimeout(resolve, 1000)); // 每秒一次查询限制
            const userInfo = userInfoResponse.data.data.list[0];
            if (!userInfo) {
                message.error('未找到相关用户信息');
                updateButtonStatus(record.id, false, 'error');
                return;
            }

            const proxyData = parseProxyInfo(record.operationInput);
            if (!proxyData) {
                message.error('输入内容不是有效的代理信息');
                updateButtonStatus(record.id, false, 'error');
                return;
            }

            const modifyData = {
                user_id: userInfo.user_id, user_proxy_config: {
                    proxy_soft: "other",
                    proxy_type: "socks5",
                    proxy_host: proxyData.ip,
                    proxy_port: proxyData.port,
                    proxy_user: proxyData.username,
                    proxy_password: proxyData.password
                }
            };

            const modifyResponse = await axios.post('http://127.0.0.1:8000/api/v1/proxy/api/v1/user/update', modifyData);
            if (modifyResponse.data.code === 0) {
                message.success(`修改钱包 ${record.ads_environment} 成功，新内容为：${record.operationInput}`);
                updateButtonStatus(record.id, false, 'success');
                fetchWallets(pagination.current, pagination.pageSize);
            } else {
                message.error(`修改钱包 ${record.ads_environment} 失败: ${modifyResponse.data.msg}`);
                updateButtonStatus(record.id, false, 'error');
            }
        } catch (error) {
            console.error('Failed to modify wallet:', error);
            message.error('修改钱包请求失败');
            updateButtonStatus(record.id, false, 'error');
        }
    }, [updateButtonStatus, pagination.current, pagination.pageSize]);

    const handleRestore = useCallback(async (record) => {
        try {
            updateButtonStatus(record.id, true);
            const userInfoResponse = await axios.get(`http://127.0.0.1:8000/api/v1/proxy/api/v1/user/list?serial_number=${record.ads_environment}`);
            await new Promise(resolve => setTimeout(resolve, 1000)); // 每秒一次查询限制
            const userInfo = userInfoResponse.data.data.list[0];
            if (!userInfo) {
                message.error('未找到相关用户信息');
                updateButtonStatus(record.id, false, 'error');
                return;
            }

            const proxyData = parseComplexProxyInfo(record.proxy);
            if (!proxyData) {
                message.error('代理IP字段不是有效的代理信息');
                updateButtonStatus(record.id, false, 'error');
                return;
            }

            const modifyData = {
                user_id: userInfo.user_id, user_proxy_config: {
                    proxy_soft: "other",
                    proxy_type: "socks5",
                    proxy_host: proxyData.ip,
                    proxy_port: proxyData.port,
                    proxy_user: proxyData.username,
                    proxy_password: proxyData.password
                }
            };

            const modifyResponse = await axios.post('http://127.0.0.1:8000/api/v1/proxy/api/v1/user/update', modifyData);
            if (modifyResponse.data.code === 0) {
                message.success(`恢复钱包 ${record.ads_environment} 成功，代理IP为：${record.proxy}`);
                updateButtonStatus(record.id, false, 'success');
                fetchWallets(pagination.current, pagination.pageSize);
            } else {
                message.error(`恢复钱包 ${record.ads_environment} 失败: ${modifyResponse.data.msg}`);
                updateButtonStatus(record.id, false, 'error');
            }
        } catch (error) {
            console.error('Failed to restore wallet:', error);
            message.error('恢复钱包请求失败');
            updateButtonStatus(record.id, false, 'error');
        }
    }, [updateButtonStatus, pagination.current, pagination.pageSize]);

    const handleQueryIP = useCallback(async (record) => {
        try {
            const userInfoResponse = await axios.get(`http://127.0.0.1:8000/api/v1/proxy/api/v1/user/list?serial_number=${record.ads_environment}`);
            await new Promise(resolve => setTimeout(resolve, 1000)); // 每秒一次查询限制
            const userInfo = userInfoResponse.data.data.list[0];
            if (userInfo) {
                const ip = userInfo.ip;
                setWallets(prevWallets => prevWallets.map(wallet => wallet.id === record.id ? {
                    ...wallet,
                    currentIP: userInfo.ip
                } : wallet));
                message.success(`当前使用IP为：${ip}`);
            } else {
                message.error('未找到相关用户信息');
            }
        } catch (error) {
            console.error('Failed to query IP:', error);
            message.error('查询IP请求失败');
        }
    }, []);

    const handleBatchQueryIP = async () => {
        setLoading(true);
        try {
            for (let i = 0; i < wallets.length; i++) {
                await handleQueryIP(wallets[i]);
            }
            message.success('批量查询成功');
        } catch (error) {
            console.error('Failed to batch query IP:', error);
            message.error('批量查询IP请求失败');
        } finally {
            setLoading(false);
        }
    };

    const parseProxyInfo = (input) => {
        if (!input) return null;
        const parts = input.split(':');
        if (parts.length !== 4) return null;
        const [ip, port, username, password] = parts;
        return {ip, port, username, password};
    };

    const parseComplexProxyInfo = (input) => {
        if (!input) return null;
        const protocolSeparatorIndex = input.indexOf('://');
        const cleanedInput = protocolSeparatorIndex > -1 ? input.slice(protocolSeparatorIndex + 3) : input;
        const parts = cleanedInput.split('@');
        if (parts.length !== 2) return null;
        const [credentials, ipPort] = parts;
        const [username, password] = credentials.split(':');
        const ipPortMatch = ipPort.match(/(.+):(\d+)$/);
        if (!ipPortMatch) return null;
        const ip = ipPortMatch[1];
        const port = ipPortMatch[2];
        return {ip, port, username, password};
    };

    const getButtonColor = (record) => {
        if (buttonStatusMap[record.id]) {
            const {type} = buttonStatusMap[record.id];
            if (type === 'success') {
                return 'green';
            } else if (type === 'error') {
                return 'red';
            } else if (type === 'running') {
                return 'blue';
            }
        }
        return '';
    };

    const getButtonText = (record) => {
        if (buttonStatusMap[record.id]) {
            const {type} = buttonStatusMap[record.id];
            if (type === 'running') {
                return '关闭';
            }
        }
        return '启动';
    };

    const handleBatchOperation = async (operation) => {
        for (let i = 0; i < selectedRowKeys.length; i++) {
            const walletId = selectedRowKeys[i];
            const record = wallets.find(wallet => wallet.id === walletId);

            if (operation === 'start') {
                await handleStart(record);
            } else if (operation === 'modify') {
                await handleModify(record);
            } else if (operation === 'restore') {
                await handleRestore(record);
            }

            await new Promise(resolve => setTimeout(resolve, 1000)); // 每秒一次操作限制
        }
    };

    const columns = useMemo(() => [{
        title: 'AdsId', dataIndex: 'ads_environment', key: 'ads_environment', width: 40
    }, {title: '组', dataIndex: 'group', key: 'group', width: 60}, {
        title: '备注', dataIndex: 'note', key: 'note', width: 60
    }, {title: '代理IP', dataIndex: 'proxy', key: 'proxy', width: 200}, {
        title: '当前使用IP', dataIndex: 'currentIP', key: 'currentIP', width: 100
    }, {
        title: '输入内容',
        key: 'operationInput',
        width: 180,
        render: (text, record) => (
            <Input value={record.operationInput} onChange={(e) => handleInputChange(e, record)}/>)
    }, {
        title: '操作', key: 'operation', fixed: 'right', width: 240, render: (text, record) => (<div>
            <Button
                type="primary"
                loading={buttonStatusMap[record.id] ? buttonStatusMap[record.id].loading : false}
                onClick={() => handleStart(record)}
                style={{backgroundColor: getButtonColor(record)}}
            >
                {buttonStatusMap[record.id] && buttonStatusMap[record.id].loading ? '启动中' : getButtonText(record)}
            </Button>
            <Button
                loading={buttonStatusMap[record.id] ? buttonStatusMap[record.id].loading : false}
                onClick={() => handleModify(record)}
                style={{backgroundColor: getButtonColor(record)}}
            >
                {buttonStatusMap[record.id] && buttonStatusMap[record.id].loading ? '修改中' : '修改'}
            </Button>
            <Button
                loading={buttonStatusMap[record.id] ? buttonStatusMap[record.id].loading : false}
                onClick={() => handleRestore(record)}
                style={{backgroundColor: getButtonColor(record)}}
            >
                {buttonStatusMap[record.id] && buttonStatusMap[record.id].loading ? '恢复中' : '恢复'}
            </Button>
            <Button
                loading={buttonStatusMap[record.id] ? buttonStatusMap[record.id].loading : false}
                onClick={() => handleQueryIP(record)}
                style={{marginLeft: 8}}
            >
                查询IP
            </Button>
        </div>),
    },], [buttonStatusMap, handleInputChange, handleStart, handleModify, handleRestore, handleQueryIP]);

    const rowSelection = {
        selectedRowKeys, onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
    };

    const handlePageChange = (page, pageSize) => {
        setPagination(prev => ({...prev, current: page, pageSize}));
    };

    return (<div>
        <div style={{marginBottom: 16}}>
            <Button type="primary" onClick={() => handleBatchOperation('start')}
                    disabled={selectedRowKeys.length === 0}>
                批量启动
            </Button>
            <Button onClick={() => handleBatchOperation('modify')} disabled={selectedRowKeys.length === 0}
                    style={{marginLeft: 8}}>
                批量修改
            </Button>
            <Button onClick={() => handleBatchOperation('restore')} disabled={selectedRowKeys.length === 0}
                    style={{marginLeft: 8}}>
                批量恢复
            </Button>
            <Button onClick={handleBatchQueryIP} disabled={wallets.length === 0} style={{marginLeft: 8}}>
                查询所有IP
            </Button>
        </div>
        <Table
            columns={columns}
            dataSource={wallets}
            loading={loading}
            rowKey="id"
            rowSelection={rowSelection}
            pagination={false}
            scroll={{y: 640}}
        />
        <Pagination
            current={pagination.current}
            pageSize={pagination.pageSize}
            total={pagination.total}
            onChange={handlePageChange}
        />
    </div>);
};

export default AdsPower;
