import React, { useState } from 'react';
import { Input, Button, Layout, Typography, message, Upload, Tabs, Table, Tooltip } from 'antd';
import { InboxOutlined, CopyOutlined, CheckOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { privateKeyEncrypt, privateKeyDecrypt } from '../utils/encryptUtils';

const { Content, Footer } = Layout;
const { TextArea } = Input;
const { Title } = Typography;
const { TabPane } = Tabs;
const { Dragger } = Upload;

const EncryptionComponent = () => {
  const [inputText, setInputText] = useState('');
  const [key, setKey] = useState('');
  const [outputText, setOutputText] = useState('');
  const [loading, setLoading] = useState(false);
  const [activeInputTab, setActiveInputTab] = useState('input');
  const [activeOutputTab, setActiveOutputTab] = useState('text');
  const [copiedKeys, setCopiedKeys] = useState(new Set());
  const [showSaveButton, setShowSaveButton] = useState(false);

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleKeyChange = (e) => {
    setKey(e.target.value);
  };

  const validateKey = (key) => {
    if (key.length !== 16) {
      message.error('密钥必须是16位字符');
      return false;
    }
    return true;
  };

  const handleEncryptText = () => {
    if (!validateKey(key)) return;

    setLoading(true);
    try {
      const lines = inputText.split('\n').filter(line => line.trim() !== '');
      const encryptedLines = lines.map(line => privateKeyEncrypt(line.trim(), key));
      setOutputText(encryptedLines.join('\n'));
      setShowSaveButton(true); // Show the save button after encryption
    } catch (error) {
      message.error('加密过程中发生错误');
    } finally {
      setLoading(false);
    }
  };

  const handleDecryptText = () => {
    if (!validateKey(key)) return;

    setLoading(true);
    try {
      const lines = outputText.split('\n').filter(line => line.trim() !== '');
      const decryptedLines = lines.map(line => privateKeyDecrypt(line.trim(), key));
      setOutputText(decryptedLines.join('\n'));
      setShowSaveButton(false); // Hide the save button after decryption
    } catch (error) {
      message.error('解密过程中发生错误');
    } finally {
      setLoading(false);
    }
  };

  const handleFileUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setInputText(e.target.result);
    };
    reader.readAsText(file);
    return false;
  };

  const handleInputTabChange = (key) => {
    setActiveInputTab(key);
    setInputText('');
    setOutputText('');
  };

  const handleOutputTabChange = (key) => {
    setActiveOutputTab(key);
  };

  const handleCopy = (key) => {
    setCopiedKeys(prevCopiedKeys => {
      const newCopiedKeys = new Set(prevCopiedKeys);
      newCopiedKeys.add(key);
      return newCopiedKeys;
    });
    message.success('内容已复制');
  };

  const formatText = (text) => {
    if (text.length <= 8) {
      return text;
    }
    return `${text.slice(0, 4)}...${text.slice(-4)}`;
  };

  const tableData = outputText.split('\n').map((line, index) => ({
    key: index,
    id: index + 1,
    text: formatText(line),
    originalText: line,
    textToCopy: line.slice(0, -4), // 不包括后四位的文本
  }));

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Line',
      dataIndex: 'text',
      key: 'text',
    },
    {
      title: '操作',
      key: 'action',
      render: (text, record) => (
        <CopyToClipboard text={record.textToCopy} onCopy={() => handleCopy(record.key)}>
          <Tooltip title="复制">
            <Button icon={copiedKeys.has(record.key) ? <CheckOutlined /> : <CopyOutlined />} />
          </Tooltip>
        </CopyToClipboard>
      ),
    },
  ];

  const saveToFile = () => {
    const blob = new Blob([outputText], { type: 'text/plain;charset=utf-8' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'encrypted_text.txt';
    link.click();
  };

  return (
    <Layout className="layout">
      <Content style={{ padding: '50px' }}>
        <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
          <div style={{ marginBottom: '20px', fontSize: '16px' }}>
            <span>密钥: </span>
            <Input
              placeholder="输入16位密钥"
              value={key}
              onChange={handleKeyChange}
              style={{ width: 'calc(100% - 60px)', display: 'inline-block' }}
            />
          </div>
          <Tabs activeKey={activeInputTab} onChange={handleInputTabChange}>
            <TabPane tab="输入模式" key="input">
              <TextArea
                placeholder="输入内容，每行一个"
                value={inputText}
                onChange={handleInputChange}
                rows={10}
                style={{ marginBottom: '20px', fontSize: '16px' }}
              />
            </TabPane>
            <TabPane tab="上传模式" key="upload">
              <Dragger
                beforeUpload={handleFileUpload}
                showUploadList={false}
                style={{ marginBottom: '20px' }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">点击或拖拽文件到此区域上传</p>
                <p className="ant-upload-hint">支持单个或多个文件上传</p>
              </Dragger>
            </TabPane>
          </Tabs>
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
            <Button
              type="primary"
              onClick={handleEncryptText}
              style={{ marginRight: '10px', backgroundColor: '#1890ff', borderColor: '#1890ff' }}
              loading={loading}
            >
              加密文本
            </Button>
            <Button
              onClick={handleDecryptText}
              style={{ backgroundColor: '#f5222d', borderColor: '#f5222d', color: '#fff' }}
              loading={loading}
            >
              解密文本
            </Button>
          </div>
          {showSaveButton && (
            <div style={{ textAlign: 'center', marginBottom: '20px' }}>
              <Button onClick={saveToFile} type="default">
                保存加密结果为TXT
              </Button>
            </div>
          )}
          <div>
            <Title level={4}>输出内容:</Title>
            <Tabs activeKey={activeOutputTab} onChange={handleOutputTabChange}>
              <TabPane tab="文本模式" key="text">
                <TextArea
                  value={outputText}
                  rows={10}
                  readOnly
                  style={{ fontSize: '16px' }}
                />
              </TabPane>
              <TabPane tab="表格模式" key="table">
                <Table
                  dataSource={tableData}
                  columns={columns}
                  pagination={false}
                  scroll={{ y: 240 }}
                />
              </TabPane>
            </Tabs>
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default EncryptionComponent;
