import { getAddress } from 'ethers';

export const convertToChecksumAddress = (addresses) => {
  return addresses.split('\n').map(address => {
    try {
      const checksummedAddress = getAddress(address.trim());
      console.log(`Original: ${address.trim()} - Checksummed: ${checksummedAddress}`);
      return checksummedAddress;
    } catch (error) {
      console.error(`Invalid address: ${address.trim()}`, error);
      return `Invalid address: ${address.trim()}`;
    }
  }).join('\n');
};
