import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Pagination, Input, Button, message, Spin, Card, Statistic, Row, Col, Select } from 'antd';
import './linea.css'; // 引入CSS文件

const { Option } = Select;

const getBalanceByAddress = async (address) => {
    const rpcUrl = 'https://rpc.xiai.me/api/query-rpc/02b28bef-7efa-4576-b943-bfd96cfb66b5/linea'; // 使用Infura的以太坊节点服务
    const requestData = {
        jsonrpc: "2.0", method: "eth_getBalance", params: [address, "latest"], id: 1
    };

    try {
        console.log('Request Data:', requestData);
        const response = await axios.post(rpcUrl, requestData);
        console.log('Response:', response.data);

        if (response.data && response.data.result) {
            const balanceInWeiHex = response.data.result;
            console.log('Balance in Wei (Hex):', balanceInWeiHex);

            const balanceInWei = BigInt(balanceInWeiHex); // 将十六进制转换为BigInt
            console.log('Balance in Wei (BigInt):', balanceInWei);

            // 将 BigInt 转换为字符串以进行浮点运算，并保留5位小数
            const balanceInEth = (Number(balanceInWei) / 1e18).toFixed(5);
            console.log('Balance in ETH:', balanceInEth);

            return balanceInEth; // 直接返回字符串
        } else {
            throw new Error('Invalid response from RPC server');
        }
    } catch (error) {
        console.error('Error fetching balance:', error);
        return "0"; // 发生异常时返回"0"
    }
};

const LineaBalance = ({ address, refresh }) => {
    const [balance, setBalance] = useState("0");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (refresh) {
            setLoading(true);
            getBalanceByAddress(address).then(balance => {
                setBalance(balance);
                setLoading(false);
            }).catch(() => {
                setBalance('0');
                setLoading(false);
            });
        }
    }, [address, refresh]);

    if (loading) {
        return <div>加载中...</div>;
    }

    const balanceValue = parseFloat(balance);

    return (
        <div>
            {balance === '0' ? (
                <span>0 ETH</span>
            ) : (
                <span style={{ color: balanceValue > 0.05 ? 'red' : 'black' }}>
                    {balance} ETH
                </span>
            )}
        </div>
    );
};

const Wallets = () => {
    const [wallets, setWallets] = useState([]);
    const [pagination, setPagination] = useState({
        current: 1, pageSize: 1000, total: 0,
    });
    const [filterType, setFilterType] = useState('address');
    const [filterValue, setFilterValue] = useState('');
    const [sortOrder, setSortOrder] = useState(null);
    const [differenceSortOrder, setDifferenceSortOrder] = useState(null);
    const [loading, setLoading] = useState(false);
    const [totalScore, setTotalScore] = useState(0);
    const [totalWalletCount, setTotalWalletCount] = useState(0);
    const [walletsWithScoreCount, setWalletsWithScoreCount] = useState(0);
    const [averageScore, setAverageScore] = useState(0);
    const [refreshBalance, setRefreshBalance] = useState(false);

    useEffect(() => {
        fetchWallets(pagination.current, pagination.pageSize, filterType, filterValue);
        fetchAllWallets();
    }, []);

    const fetchWallets = async (page = 1, pageSize = 10, filterType = '', filterValue = '') => {
        setLoading(true);
        try {
            const response = await axios.get(`http://127.0.0.1:8000/api/v1/history/wallets_with_history`, {
                params: {
                    page: page, limit: pageSize, [filterType]: filterValue
                }
            });
            const data = response.data;

            if (data && data.wallets) {
                const walletsWithDifference = data.wallets.map(wallet => {
                    const history = wallet.history;
                    const currentXp = history[0]?.xp || 0;
                    const previousXp = history[1]?.xp || 0;
                    return { ...wallet, difference: currentXp - previousXp };
                });

                setWallets(walletsWithDifference);
                setPagination({
                    current: data.page, pageSize: data.limit, total: data.total_count,
                });
            } else {
                setWallets([]);
                setPagination({
                    current: page, pageSize: pageSize, total: 0,
                });
            }
        } catch (error) {
            console.error('Error fetching wallets:', error);
            message.error('Failed to fetch wallets');
        } finally {
            setLoading(false);
        }
    };

    const fetchAllWallets = async () => {
        try {
            const response = await axios.get(`http://127.0.0.1:8000/api/v1/history/wallets_with_history`, {
                params: {
                    page: 1, limit: 99999
                }
            });
            const data = response.data;
            calculateTotalScoreAndCount(data.wallets);
        } catch (error) {
            message.error('Failed to fetch all wallets');
        }
    };

    const calculateTotalScoreAndCount = (wallets) => {
        const total = wallets.reduce((sum, wallet) => sum + (wallet.history[0]?.xp || 0), 0);
        const walletsWithScore = wallets.filter(wallet => wallet.history[0]?.xp > 0).length;
        setTotalScore(total);
        setTotalWalletCount(wallets.length);
        setWalletsWithScoreCount(walletsWithScore);
        setAverageScore(walletsWithScore ? (total / walletsWithScore).toFixed(2) : 0);
    };

    const handlePageChange = (page, pageSize) => {
        fetchWallets(page, pageSize, filterType, filterValue);
    };

    const handleFilterTypeChange = (value) => {
        setFilterType(value);
    };

    const handleFilterValueChange = (e) => {
        setFilterValue(e.target.value);
    };

    const handleSearch = () => {
        fetchWallets(1, pagination.pageSize, filterType, filterValue);
    };

    const handleReset = () => {
        setFilterType('address');
        setFilterValue('');
        fetchWallets(1, pagination.pageSize, 'address', '');
    };

    const handleRefresh = async () => {
        setLoading(true);
        try {
            await axios.get('http://127.0.0.1:8000/api/v1/history/refresh');
            message.success('Refreshed successfully');
            fetchAllWallets();
        } catch (error) {
            message.error('Failed to refresh');
        } finally {
            setLoading(false);
        }
    };

    const handleTableChange = (pagination, filters, sorter) => {
        const order = sorter.field === 'difference' ? sorter.order : sorter.order === 'ascend' ? 'xp_asc' : sorter.order === 'descend' ? 'xp_desc' : null;
        if (sorter.field === 'difference') {
            setDifferenceSortOrder(sorter.order);
        } else {
            setSortOrder(order);
        }
        // 前端排序
        let sortedWallets = [...wallets];
        if (order === 'xp_asc') {
            sortedWallets.sort((a, b) => a.history[0]?.xp - b.history[0]?.xp);
        } else if (order === 'xp_desc') {
            sortedWallets.sort((a, b) => b.history[0]?.xp - a.history[0]?.xp);
        } else if (sorter.field === 'difference' && sorter.order) {
            sortedWallets.sort((a, b) => sorter.order === 'ascend' ? a.difference - b.difference : b.difference - a.difference);
        }
        setWallets(sortedWallets);
    };

    const handleRefreshBalance = () => {
        setRefreshBalance(prevState => !prevState);
    };

    const columns = [
        { title: 'ADS', dataIndex: 'ads_environment', key: 'ads_environment', width: 60 },
        { title: '组', dataIndex: 'group', key: 'group', width: 80 },
        { title: '钱包ID', dataIndex: 'wallet_id', key: 'group', width: 80 },
        { title: '类型', dataIndex: 'wallet_type', key: 'wallet_type', width: 80 },
        { title: '地址', dataIndex: 'address', key: 'address', width: 280 },
        {
            title: '余额', key: 'linea_tx_count', width: 120, render: (text, record) => {
                return <LineaBalance address={record.address} refresh={refreshBalance} />;
            }
        },
        { title: '排名', dataIndex: ['history', 0, 'rank'], key: 'rank', sorter: true, width: 120 },
        { title: '总分数', dataIndex: ['history', 0, 'xp'], key: 'total_score', sorter: true, width: 120 },
        {
            title: '分数差异', key: 'difference', sorter: true, width: 120, render: (text, record) => {
                return <span>{record.difference}</span>;
            },
        },
    ];

    const expandedRowRender = (record) => {
        const historyData = record.history.sort((a, b) => new Date(a.date) - new Date(b.date));
        const historyColumns = [
            { title: '日期', dataIndex: 'date', key: 'date' },
            { title: 'XP分数', dataIndex: 'xp', key: 'xp' },
            { title: '排名', dataIndex: 'rank', key: 'rank' },
            {
                title: '分数差异', key: 'difference', render: (text, row, index) => {
                    const previousXp = index > 0 ? historyData[index - 1].xp : 0;
                    const difference = row.xp - previousXp;
                    return (<span>{difference}</span>);
                }
            }
        ];
        return (
            <Table
                columns={historyColumns}
                dataSource={historyData}
                rowKey="id"
                pagination={false}
            />
        );
    };

    const tableStyle = { fontSize: '12px' };
    const inputStyle = { width: 200, marginRight: 10 };
    const buttonStyle = { marginRight: 10 };
    const statisticCardStyle = { padding: '10px', fontSize: '12px' };
    const statisticStyle = { fontSize: '12px' };

    return (
        <Spin spinning={loading}>
            <div style={{ width: '100%', margin: '0 auto' }}>
                <div style={{ marginBottom: 10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <Select defaultValue="address" style={{ width: 200, marginRight: 10 }} onChange={handleFilterTypeChange}>
                        <Option value="address">钱包地址</Option>
                        <Option value="group">钱包组</Option>
                        <Option value="exchange_address">交易所地址</Option>
                        <Option value="ads_environment">ADS环境</Option>
                    </Select>
                    <Input placeholder="输入查询值" value={filterValue} onChange={handleFilterValueChange} style={inputStyle} />
                    <Button type="primary" onClick={handleSearch} style={buttonStyle}>搜索</Button>
                    <Button onClick={handleReset} style={buttonStyle}>重置</Button>
                    <Button type="primary" onClick={handleRefresh} style={{ ...buttonStyle, backgroundColor: '#1890ff', borderColor: '#1890ff' }}>刷新分数</Button>
                    <Button type="primary" onClick={handleRefreshBalance} style={buttonStyle}>刷新余额</Button>
                </div>
                <Row gutter={16} style={{ marginBottom: 10 }}>
                    <Col span={6}>
                        <Card style={statisticCardStyle}>
                            <Statistic title="总钱包地址数量" value={totalWalletCount} style={statisticStyle} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card style={statisticCardStyle}>
                            <Statistic title="有分数的钱包数量" value={walletsWithScoreCount} style={statisticStyle} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card style={statisticCardStyle}>
                            <Statistic title="总分数" value={totalScore} style={statisticStyle} />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card style={statisticCardStyle}>
                            <Statistic title="平均分数" value={averageScore} style={statisticStyle} />
                        </Card>
                    </Col>
                </Row>
                <Table
                    columns={columns}
                    dataSource={wallets}
                    rowKey="id"
                    pagination={false}
                    style={tableStyle}
                    onChange={handleTableChange}
                    scroll={{ y: 580 }} // 固定高度并增加纵向滚动
                    expandedRowRender={expandedRowRender}
                    rowClassName={(record) => record.difference === 0 ? 'row-zero-difference' : ''}
                />
                <Pagination
                    current={pagination.current}
                    pageSize={pagination.pageSize}
                    total={pagination.total}
                    onChange={handlePageChange}
                    pageSizeOptions={['10', '50', '100', '200', '400', '500','530', '1000']}
                />
            </div>
        </Spin>
    );
};

export default Wallets;
