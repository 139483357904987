import CryptoJS from 'crypto-js';

const ensureKeyLength = (key, desiredLength = 16) => {
    if (key.length > desiredLength) {
        return key.slice(0, desiredLength);
    } else if (key.length < desiredLength) {
        return key.padEnd(desiredLength, '\0');
    }
    return key;
};

export const aesEncrypt = (message, key) => {
    key = ensureKeyLength(key);
    const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
    const ivUtf8 = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(message), keyUtf8, {
        iv: ivUtf8,
        padding: CryptoJS.pad.Pkcs7,
        mode: CryptoJS.mode.CBC
    });
    return encrypted.ciphertext.toString(CryptoJS.enc.Hex);
};

export const aesDecrypt = (encryptedHex, key) => {
    key = ensureKeyLength(key);
    const keyUtf8 = CryptoJS.enc.Utf8.parse(key);
    const ivUtf8 = CryptoJS.enc.Utf8.parse(key);
    const encrypted = CryptoJS.enc.Hex.parse(encryptedHex);
    const decrypted = CryptoJS.AES.decrypt(
        {
            ciphertext: encrypted
        },
        keyUtf8,
        {
            iv: ivUtf8,
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC
        }
    );
    return decrypted.toString(CryptoJS.enc.Utf8);
};

export const privateKeyEncrypt = (originalPrivateKey, key) => {
    const keyCharacter = String.fromCharCode(originalPrivateKey.charCodeAt(originalPrivateKey.length - 1) + 1);
    const waitingEncryptPrivateKey = originalPrivateKey.slice(0, -1) + keyCharacter;
    return aesEncrypt(waitingEncryptPrivateKey, key);
};

export const privateKeyDecrypt = (encryptedPrivateKey, key) => {
    const decryptedPrivateKey = aesDecrypt(encryptedPrivateKey, key);
    const keyCharacter = String.fromCharCode(decryptedPrivateKey.charCodeAt(decryptedPrivateKey.length - 1) - 1);
    return decryptedPrivateKey.slice(0, -1) + keyCharacter;
};
