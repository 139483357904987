import React, {useState, useEffect, useCallback} from 'react';
import {Table, Button, Modal, Form, Input, Switch, Select, Space, message} from 'antd';
import axios from 'axios';

const {Option} = Select;
const {confirm} = Modal;

const typeMapping = {
    "Bark通知": "BARK_notification",
    "电报通知": "tele_notification",
    "全局Socks5代理": "global_Socks5_proxy",
    "微信Service酱通知": "WeChat_Service_Notification",
    "OKX交易所配置": "OKX_exchange_config",
    "币安交易所配置": "Binance_exchange_config",
    "Bitget交易所配置": "Bitget_exchange_config",
    "Gate交易所配置": "Gate_exchange_config",
    "YesCaptchaKey": "yes_captcha_config",
    "2captcha": "2_captcha_config",
};

const ConfigPage = () => {
    const [configs, setConfigs] = useState([]);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [editingConfig, setEditingConfig] = useState(null);
    const [showConfigs, setShowConfigs] = useState(false);
    const [selectedType, setSelectedType] = useState('');
    const [form] = Form.useForm();

    const fetchConfigs = useCallback(async () => {
        try {
            const response = await axios.get('http://127.0.0.1:8000/api/v1/configs');
            setConfigs(response.data);
        } catch (error) {
            message.error('获取配置失败');
        }
    }, []);

    useEffect(() => {
        fetchConfigs();
    }, [fetchConfigs]);

    const handleAdd = () => {
        setEditingConfig(null);
        form.resetFields();
        setSelectedType('');
        setIsModalVisible(true);
    };

    const handleEdit = (record) => {
        setEditingConfig(record);
        const initialValues = {
            ...record, type: Object.keys(typeMapping).find(key => typeMapping[key] === record.type),
        };
        if (record.type === 'tele_notification') {
            initialValues.config = record.config;
        }
        if (["OKX_exchange_config", "Binance_exchange_config", "Bitget_exchange_config", "Gate_exchange_config"].includes(record.type)) {
            const config = JSON.parse(record.config);
            initialValues.apiKey = config.apiKey;
            initialValues.secret = config.secret;
            initialValues.password = config.password;
        }
        form.setFieldsValue(initialValues);
        setSelectedType(Object.keys(typeMapping).find(key => typeMapping[key] === record.type));
        setIsModalVisible(true);
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`http://127.0.0.1:8000/api/v1/configs/${id}`);
            message.success('配置已删除');
            fetchConfigs();
        } catch (error) {
            message.error('删除配置失败');
        }
    };

    const showDeleteConfirm = (id) => {
        confirm({
            title: '确认删除',
            content: '您确定要删除这条配置吗？此操作不可恢复。',
            okText: '确认',
            okType: 'danger',
            cancelText: '取消',
            onOk() {
                handleDelete(id);
            }
        });
    };

    const handleOk = async () => {
        try {
            const values = await form.validateFields();
            let configValue = values.config;
            if (["OKX交易所配置", "币安交易所配置", "Bitget交易所配置", "Gate交易所配置"].includes(values.type)) {
                configValue = JSON.stringify({
                    apiKey: values.apiKey, secret: values.secret, password: values.password
                });
            }
            const formattedValues = {
                ...values, type: typeMapping[values.type], config: configValue
            };

            if (values.type === '全局Socks5代理' && !editingConfig) {
                const existingGlobalSocks5 = configs.find(config => config.type === 'global_Socks5_proxy');
                if (existingGlobalSocks5) {
                    message.error('只能添加一个全局Socks5代理配置');
                    return;
                }
            }

            if (editingConfig) {
                await axios.put(`http://127.0.0.1:8000/api/v1/configs/${editingConfig.id}`, formattedValues);
                message.success('配置已更新');
            } else {
                await axios.post('http://127.0.0.1:8000/api/v1/configs', formattedValues);
                message.success('配置已添加');
            }
            setIsModalVisible(false);
            fetchConfigs();
        } catch (error) {
            message.error('保存配置失败');
        }
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    const handleStatusChange = async (checked, record) => {
        try {
            await axios.put(`http://127.0.0.1:8000/api/v1/configs/${record.id}`, {
                ...record, enabled: checked,
            });
            message.success('状态已更新');
            fetchConfigs();
        } catch (error) {
            message.error('更新状态失败');
        }
    };


    const handleTypeChange = (value) => {
        setSelectedType(value);
    };

    const columns = [{
        title: '类型',
        dataIndex: 'type',
        key: 'type',
        align: 'center',
        render: (text) => Object.keys(typeMapping).find(key => typeMapping[key] === text)
    }, {
        title: '配置', dataIndex: 'config', key: 'config', align: 'center', render: (text) => showConfigs ? text : '***'
    }, {
        title: '状态', dataIndex: 'enabled', key: 'enabled', align: 'center', render: (text, record) => (<Switch
            checked={record.enabled}
            onChange={(checked) => handleStatusChange(checked, record)}
        />),
    }, {
        title: '备注', dataIndex: 'remark', key: 'remark', align: 'center',
    }, {
        title: '操作', key: 'actions', align: 'center', render: (text, record) => (<Space size="middle">
            <Button onClick={() => handleEdit(record)}>编辑</Button>
            <Button onClick={() => showDeleteConfirm(record.id)} danger>
                删除
            </Button>
        </Space>),
    }];

    return (<div style={{
        padding: '20px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        minHeight: '100vh',
        justifyContent: 'flex-start',
        width: '100%',
    }}>
        <div style={{
            width: '80%',
            maxWidth: '800px',
            marginTop: '20px',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 1px 3px rgba(0, 0, 0, 0.2)'
        }}>
            <Space style={{marginBottom: '10px'}}>
                <Button type="primary" onClick={handleAdd}>
                    添加配置
                </Button>
            </Space>
            <Table
                columns={columns}
                dataSource={configs}
                rowKey="id"
                style={{width: '100%'}}
                pagination={{position: ['bottomCenter']}}
            />
        </div>
        <Modal
            title={editingConfig ? '编辑配置' : '添加配置'}
            visible={isModalVisible}
            onOk={handleOk}
            onCancel={handleCancel}
        >
            <Form
                form={form}
                layout="vertical"
                initialValues={{enabled: true}} // 设置默认值
            >
                <Form.Item
                    name="type"
                    label="类型"
                    rules={[{required: true, message: '请选择类型!'}]}
                >
                    <Select onChange={handleTypeChange}>
                        {Object.keys(typeMapping).map(key => (<Option key={key} value={key}>{key}</Option>))}
                    </Select>
                </Form.Item>
                {selectedType === '电报通知' && (<>
                    <Form.Item
                        name="config"
                        label="配置"
                        rules={[{required: true, message: '请输入配置!'}]}
                    >
                        <Input.TextArea/>
                    </Form.Item>
                    <Form.Item>
                        <div style={{marginBottom: '16px', color: 'red'}}>
                            访问链接 <a href="https://t.me/ViewBodyBot" target="_blank"
                                        rel="noopener noreferrer">https://t.me/ViewBodyBot</a><br/> 发送/start
                            获取电报用户ID 填写在下方,注意只需要数字
                        </div>
                    </Form.Item>
                </>)}
                {["OKX交易所配置", "币安交易所配置", "Bitget交易所配置", "Gate交易所配置"].includes(selectedType) && (<>
                    <Form.Item
                        name="apiKey"
                        label="API Key"
                        rules={[{required: true, message: '请输入API Key!'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="secret"
                        label="密钥"
                        rules={[{required: true, message: '请输入密钥!'}]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="密码"
                    >
                        <Input/>
                    </Form.Item>
                </>)}
                {selectedType !== '电报通知' && !["OKX交易所配置", "币安交易所配置", "Bitget交易所配置", "Gate交易所配置"].includes(selectedType) && (
                    <Form.Item
                        name="config"
                        label="配置"
                        rules={[{required: true, message: '请输入配置!'}]}
                    >
                        <Input.TextArea/>
                    </Form.Item>)}
                <Form.Item name="enabled" label="状态" valuePropName="checked">
                    <Switch/>
                </Form.Item>
                <Form.Item name="remark" label="备注">
                    <Input.TextArea/>
                </Form.Item>
            </Form>
        </Modal>
    </div>);
};

export default ConfigPage;
