import React, { useState } from 'react';
import { Input, Button, Layout, Typography } from 'antd';
import { convertToChecksumAddress } from '../utils/addressUtils'; // 导入封装的函数

const { Content, Footer } = Layout;
const { TextArea } = Input;
const { Title } = Typography;

const TextFormatter = () => {
  const [inputText, setInputText] = useState('');
  const [formattedText, setFormattedText] = useState('');

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleFormatText = () => {
    const formatted = convertToChecksumAddress(inputText);
    setFormattedText(formatted);
  };

  return (
    <Layout className="layout">
      <Content style={{ padding: '50px' }}>
        <div style={{ background: '#fff', padding: 24, minHeight: 280 }}>
          <TextArea
            placeholder="输入钱包地址，每行一个"
            value={inputText}
            onChange={handleInputChange}
            rows={10}
            style={{ marginBottom: '20px', fontSize: '16px' }}
          />
          <Button type="primary" onClick={handleFormatText}>
            校验钱包格式
          </Button>
          <div style={{ marginTop: '20px' }}>
            <Title level={4}>转换后的钱包地址:</Title>
            <TextArea
              value={formattedText}
              rows={10}
              readOnly
              style={{ fontSize: '16px' }}
            />
          </div>
        </div>
      </Content>
      <Footer style={{ textAlign: 'center' }}>
        Ant Design ©2023 Created by Ant UED
      </Footer>
    </Layout>
  );
};

export default TextFormatter;
